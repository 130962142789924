import React from 'react';


function OkoliczneAtrakcje() {
    return (
        <div className='div-zdjecia'>


        <div className='grid'>

        <div class="row"> 
        <div class="column">
      
        <img src="images/atrakcje/373294779_142295255601464_3725692943057105024_n.jpg" style={{width:"100%"}}></img>
        <img src="images/atrakcje/367449992_130485466782443_648078680893792610_n.jpg" style={{width:"100%"}}></img>

        </div>
        <div class="column">
        <img src="images/atrakcje/IMG_3097.jpg" style={{width:"100%"}}></img>
        <img src="images/atrakcje/IMG_3093.jpg" style={{width:"100%"}}></img>
        <img src="images/atrakcje/IMG_3106.jpg" style={{width:"100%"}}></img>
        </div>  
        <div class="column">
        <img src="images/atrakcje/367392984_130485460115777_7472509148698654502_n.jpg" style={{width:"100%"}}></img> 
        <img src="images/atrakcje/367449459_130485380115785_2871563078405379171_n.jpg" style={{width:"100%"}}></img>
        <img src="images/atrakcje/367421851_130485543449102_4931356662743588916_n.jpg" style={{width:"100%"}}></img>

        </div>
        <div class="column">
        <img src="images/atrakcje/367383378_130485363449120_4730021308156445699_n.jpg" style={{width:"100%"}}></img>
        <img src="images/atrakcje/367470107_130485503449106_5741420816696972122_n.jpg" style={{width:"100%"}}></img> 

        </div>
        </div>
        </div>
</div>
);
};
    export default OkoliczneAtrakcje;