import React from 'react';
import './Gallery.css';

import {Link} from 'react-router-dom';

const Galery = () => {
    return (
        <div className="galery-div">
            <div className="galery-kafle">
            <Link to='/pokoje' className={"link-galery"}><div className="galery-icons" id="icon1"><h4>Pokoje</h4></div></Link>
            <Link to='/kuchnia'className="link-galery"><div className="galery-icons" id="icon2"><h4>Kuchnia</h4></div></Link>
            <Link to='/poddasze'className="link-galery"><div className="galery-icons" id="icon3"><h4>Poddasze</h4></div></Link>
            <Link to='/nasze-siedlisko'className="link-galery"><div className="galery-icons" id="icon4"><h4>Nasze Siedlisko</h4></div></Link>
            <Link to='/imprezy'className="link-galery"><div className="galery-icons" id="icon5"><h4>Imprezy</h4></div></Link>
            <Link to='/okoliczne-atrakcje'className="link-galery"><div className="galery-icons" id="icon6"><h4>Okoliczne Atrakcje</h4></div></Link>
            </div>

            
        </div>

        );
    }
    
    export default Galery;