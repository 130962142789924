import React, { Component } from 'react';
import './Contact.css';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            title: '',
            name: '',
            email: ''
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.formValid()) {
            const templateId = 'template_o7t7jnj';
            this.sendFeedback(templateId, {
                message: this.state.message,
                title: this.state.title,
                name: this.state.name,
                email: this.state.email
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Błąd!',
                text: 'Proszę wypełnić wszystkie pola formularza!'
            });
        }
    }

    formValid = () => {
        const { name, email, title, message } = this.state;
        return name.trim() !== '' && email.trim() !== '' && title.trim() !== '' && message.trim() !== '';
    }

    sendFeedback = (templateId, variables) => {
        window.emailjs.send(
            'siedlisko_service', templateId,
            variables
        ).then(res => {
            Swal.fire({
                icon: 'success',
                title: 'Poszło!',
                text: 'Twoja wiadomość została wysłana!'
            }).then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            });
        }).catch(err => {
            console.error('Email Error:', err);
        });
    }

    render() {
        return (
            <div className='contact-div'>
                <div className='jak-dojechac'>
                     <h2>Gdzie znajduje się nasze Siedlisko?</h2>
                     <h4>Siedlisko położone jest w małej, malowniczej, podlaskiej wsi Wojszki w gminie Mońki. To bardzo ważne, ponieważ na Podlasiu znajdują się aż 2 miejscowości o nazwie Wojszki.</h4>
                    <h4> Wojszki to miejscowość oddalona od miasta Mońki o 7 km (znajdziecie tam duże sklepy, stacje benzynowe, restauracje itp.) oraz od miasta Białystok o ok. 40km. </h4>
                    <h4>Dojechać do nas można drogą ekspresową S8 od strony Warszawy, w stronę Białegostoku, skręcając na Rzędziany i jadąc cały czas drogą asfaltową, mijając zabytkową miejscowość Tykocin (synagoga żydowska, zamek, restauracje żydowskie). W Knyszynie należy skręcić w lewo w drogę DK65, dojedziecie do Moniek i w nich należy skręcić w ulicę Szkolną w strone miejscowości Kołodzież. Wojszki będą znajdować się 7km dalej, (3km drogi żwirowej). </h4>
                    <h4>Jadąc przez Białystok, możecie do nas trafić kierując się na drogę DK65 w stronę Ełku.</h4>
                    <a href="https://www.google.pl/maps/dir//Wojszki+1,+19-100+Wojszki/@53.3872418,22.7392131,19.25z/data=!4m9!4m8!1m0!1m5!1m1!1s0x46e02682139dfd5d:0x9ffac9fa46846e71!2m2!1d22.7395579!2d53.3876529!3e0?entry=ttu">
                      <button className='contact-button'>Mapa w Google Maps</button>
                    </a>
                </div>
                <div className='contact-icons'>
                    <h2>Ile czasu zajmuje dojazd do nas?</h2>
                    <Fade left><div className='city-icon'><h4><i className="fa fa-location-dot"></i> Warszawa ok. 200km<p><i className="fa fa-clock"></i> 2,5h</p></h4></div></Fade>
                    <Fade left><div className='city-icon'><h4><i className="fa fa-location-dot"></i> Białystok ok. 40km<p><i className="fa fa-clock"></i> 40 min</p></h4></div></Fade>
                    <Fade right><div className='city-icon'><h4><i className="fa fa-location-dot"></i> Olsztyn ok. 200km<p><i className="fa fa-clock"></i> 3h</p></h4></div></Fade>
                    <Fade right><div className='city-icon'><h4><i className="fa fa-location-dot"></i> Lublin ok. 300km<p><i className="fa fa-clock"></i> 4h</p></h4></div></Fade>
                </div>
                <div className='form-contact'>
                    <div className="contact-us">   
                        <h2>Formularz kontaktowy</h2>
                        <form action="#" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Imię <em>&#x2a;</em></label>
                                <input id="name" name="name" value={this.state.name} onChange={this.handleChange} required type="text" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Adres e-mail <em>&#x2a;</em></label>
                                <input id="email" name="email" value={this.state.email} onChange={this.handleChange} required type="email" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="title">Temat <em>&#x2a;</em></label>
                                <input id="title" name="title" value={this.state.title} onChange={this.handleChange} required type="text" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Zapytanie <em>&#x2a;</em></label>
                                <textarea id="message" name="message" value={this.state.message} onChange={this.handleChange} required rows="4"></textarea>
                            </div>
                            <input className='contact-button' id="submit-contact" type="submit" value="Wyślij" />
                        </form>
                    </div>
                    <div className='contact-us-social'>
                        <h2 style={{textAlign:"center"}}>Odwiedź nasze social media</h2>
                        <h4 className="social-icon" style={{textAlign:"center",marginTop:"10%"}}><a href="https://www.facebook.com/profile.php?id=100094629690454"> <FontAwesomeIcon icon={faFacebook} size='2x' /></a> Siedlisko w Słońcu Podlasia</h4>
                        <h4 className="social-icon" style={{textAlign:"center"}}><a href="https://www.instagram.com/siedliskowsloncu/" ><FontAwesomeIcon icon={faInstagram} size='2x' /></a> siedliskowsloncu</h4>
                        <h4 className="social-icon" style={{textAlign:"center"}}><i className="fa fa-envelope-circle-check fa-2x"></i> kontakt@siedliskowsloncu.pl</h4>
                        <h4 className="social-icon" style={{textAlign:"center"}}><i className="fa fa-location-dot fa-2x"></i> Wojszki 1, 19-100 Mońki, Woj. Podlaskie</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;