import React from 'react';
import './Price.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Price = () => {
    return (
        <div className="price-div">
        <h2>Cennik 2024</h2>

        <table className="price-table">
                    <tr>
                        <th className="price-th" colspan="2">W SEZONIE (1.III - 1.X.2025)* <p className="p-price">o cenie decyduje liczba osób</p></th>
                    </tr>
                    <tr>
                        <td className="price-td">Piątek - Niedziela (Weekend)</td>
                        <td className="price-td">1500-1800zł /doba/cały dom <p>2300-2800zł /2doby/cały dom</p></td>
                    </tr>
                    <tr>
                        <td className="price-td">Niedziela - Piątek (Dni robocze)</td>
                        <td className="price-td">500-750zł /doba/cały dom</td>
                    </tr>
                    <tr>
                        <th className="price-th" colspan="2">ŚWIĘTA I DNI WOLNE W 2025* <p className="p-price">o cenie decyduje liczba osób</p></th>
                    </tr>
                    <tr>
                        <td className="price-td">Wielkanoc 18.IV -21.IV.2025 (min. 2 doby)</td>
                        <td className="price-td">3500zł / 3 doby/cały dom</td>
                    </tr>
                    <tr>
                        <td className="price-td">Majówka 30.IV - 4.V.2024 (min. 2 doby)</td>
                        <td className="price-td">4300zł / 4 doby/cały dom</td>
                    </tr>
                    <tr>
                        <td className="price-td">Boże ciało 19.VI - 22.VI.2025 (min. 2 doby)</td>
                        <td className="price-td">3300zł / 3 doby/cały dom</td>
                    </tr>
                    <tr>
                        <td className="price-td">Wniebowzięcie Najświętszej Maryi Panny 15.VIII - 17.VIII.2025 (min. 2 doby)</td>
                        <td className="price-td">3300zł / 2 doby/cały dom</td>
                    </tr>
                    </table>

                    <div className="price-podtabela">
                        <h3 className="price-h3">* Propozycje cen do negocjacji</h3>
                        <h3 className="price-h3">* O cenie decyduje, liczba osób</h3>
                        <h3 className="price-h3">* Potwierdzeniem rezerwacji jest wysłanie zaliczki w kwocie 300zł</h3>
                        <h3 className="price-h3">* Do ceny wynajmu należy doliczyć 500zł kaucji, zwracanej przy wyjeżdzie</h3>
                        <h3 className="price-h3">* Dodatkowo dekoracje obiektu na każdą okazje - od 300zł</h3>
                    </div>

                    <div className="price-dodatkowo">
                    <h2>Cena Obejmuje</h2>

                     <div className="price-icons"><h2><i class="fa fa-water-ladder"></i></h2><h4 className="price-h4"> Basen (rozkładany w sezonie letnim 7m x 3m)</h4></div>
                     <div className="price-icons"><h2><i class="fa fa-bath"></i></h2><h4 className="price-h4"> Balia + opał</h4></div>
                     <div className="price-icons"><h2><i class="fa fa-fire"></i></h2><h4 className="price-h4"> Ognisko</h4></div>
                     <div className="price-icons"><h2><i class="fa fa-fire"></i></h2><h4 className="price-h4"> Grill</h4></div>                     
                     <div className="price-icons"><h2><i class="fa fa-p"></i></h2><h4 className="price-h4"> Parking</h4></div>
                     <div className="price-icons"><h2><i class="fa fa-wifi"></i></h2><h4 className="price-h4"> Wi-Fi</h4></div>
                     <div className="price-icons"><h2><i class="fa fa-dice"></i></h2><h4 className="price-h4"> Gry planszowe i różne książki</h4></div>
                    <div className="price-icons"><h2><i class="fa fa-kitchen-set"></i></h2><h4 className="price-h4"> Pełne wyposażenie kuchni</h4></div>
                    <div className="price-icons"><h2><i class="fa fa-scroll"></i></h2><h4 className="price-h4"> Ręczniki</h4></div>
                    <div className="price-icons"><h2><i class="fa fa-futbol"></i></h2><h4 className="price-h4"> Stół 3w1 (piłkarzyki, bilard, cymbergaj)</h4></div>
                    <div className="price-icons"><h2><i class="fa fa-house-chimney"></i></h2><h4 className="price-h4"> Duże poddasze użytkowe</h4></div>
                    <div className="price-icons"><h2><i class="fa fa-fish-fins"></i></h2><h4 className="price-h4"> Stawy rekreacyjne</h4></div>

                    </div>
       
                    <div className="price-dodatkowo">
                    <h2>Dodatkowo</h2>
                    <h4>Mozliwość wyżywienia prosto od Pań z Koła Gospodyń Wiejskich po wcześniejszym uzgodnieniu</h4>
                    <h4><i class="fa fa-sun"></i> Zobacz przykładowe menu</h4>
                    </div>
</div>
        );
    }
    
    export default Price;