import React from 'react';


function NaszeSiedlisko() {
        return (
            <div className='div-zdjecia'>

                    
                <div className='grid'>

                <div class="row"> 
                <div class="column"> 
                    <img src="images/siedlisko/22.JPG" style={{width:"100%"}}></img>
                    <img src="images/siedlisko/44-min.jpg" style={{width:"100%"}}></img>
                  <img src="images/siedlisko/IMG_6949.jpg" style={{width:"100%"}}></img>
                  <img src="images/siedlisko/IMG_6386_Easy-Resize.com.jpg" style={{width:"100%"}}></img>
              
                <img src="images/siedlisko/416030099_216922668138722_2416603446488573014_n.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_4897.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_5475.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_4893.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_4871.jpg" style={{width:"100%"}}></img>

                </div>
                <div class="column">
                 <img src="images/siedlisko/IMG_7247.jpg" style={{width:"100%"}}></img>  
                 <img src="images/siedlisko/IMG_7305.jpg" style={{width:"100%"}}></img>
                  <img src="images/siedlisko/IMG_6945.jpg" style={{width:"100%"}}></img>
                  <img src="images/siedlisko/unnamed (14).jpg" style={{width:"100%"}}></img>
                
                <img src="images/siedlisko/369807648_135408666290123_6484482261269435947_n.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_4095.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_4131.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/unnamed.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_2864.jpg" style={{width:"100%"}}></img>

                </div>  
                <div class="column">
                    <img src="images/siedlisko/unnamed (6).jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_7449.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/369720520_135408689623454_2866130715691005630_n.jpg" style={{width:"100%"}}></img> 
                
                
                <img src="images/siedlisko/369630466_135409566290033_1503521299309180073_n.jpg" style={{width:"100%"}}></img> 
                <img src="images/siedlisko/369654888_135409426290047_8313002398824112238_n.jpg" style={{width:"100%"}}></img> 
                <img src="images/siedlisko/IMG_2863.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_3348.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_3270.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_3450.jpg" style={{width:"100%"}}></img>
                
                </div>

                <div class="column">
                <img src="images/siedlisko/IMG_6467.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_7493.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/11-min.JPG" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_7393.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_6368.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_6369.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_3373.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_3361.jpg" style={{width:"100%"}}></img>
                <img src="images/siedlisko/IMG_3382.jpg" style={{width:"100%"}}></img>
                

                
                </div>
                </div>
                </div>
    </div>
);
};
    
    export default NaszeSiedlisko;