import React from 'react';


function Imprezy() {
        return (
            <div className='div-zdjecia'>


            <div className='grid'>

            <div class="row"> 
            <div class="column">
            
            <img src="images/imprezy/0BFC1ED3-771D-446F-9286-585C0AF5B8F0.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/06BB29D0-4F5F-4EB5-8F0A-887F2AB2D9DE.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/633309CA-8151-4825-ADFC-E01D505CEEE3.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/33-min.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_6994.jpg" style={{width:"100%"}}></img>
            </div>
            <div class="column">
            
            <img src="images/imprezy/IMG_4985.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_4993.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_4964.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_4957.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_7014.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_7001.jpg" style={{width:"100%"}}></img>
            </div>  

            <div class="column">
            
            <img src="images/imprezy/IMG_4955.jpg" style={{width:"100%"}}></img> 
            <img src="images/imprezy/IMG_5010.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_E4975.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_7012.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_6995(2).jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_7003.jpg" style={{width:"100%"}}></img>
            </div>

            <div class="column">
            
            <img src="images/imprezy/IMG_5030.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_5028.jpg" style={{width:"100%"}}></img> 
            <img src="images/imprezy/IMG_5045.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_7010.jpg" style={{width:"100%"}}></img>
            <img src="images/imprezy/IMG_7018.jpg" style={{width:"100%"}}></img>
            </div>
            </div>
            </div>
    </div>
);
};
    export default Imprezy;