import React from 'react';
import './zdjecia.css';

function Poddasze() {
    return (
        
    <div className='div-zdjecia'>

<div className='grid'>

<div class="row"> 
<div class="column">
<img src="images/poddasze/IMG_3193.jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/IMG_6426.jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/IMG_3231.jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/IMG_3227.jpg" style={{width:"100%"}}></img> 

</div>

<div class="column">
<img src="images/poddasze/IMG_7004.jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/IMG_2957.jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/IMG_2962.jpg" style={{width:"100%"}}></img>

</div>  

<div class="column">
<img src="images/poddasze/unnamed (3).jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/369654309_135409059623417_1931728441484990691_n.jpg" style={{width:"100%"}}></img>
<img src="images/poddasze/369608192_135408996290090_1067512343157436395_n.jpg" style={{width:"100%"}}></img>
</div>

<div class="column">
    <img src="images/poddasze/IMG_3197.jpg" style={{width:"100%"}}></img>
   <img src="images/poddasze/IMG_6432.jpg" style={{width:"100%"}}></img>
   <img src="images/poddasze/IMG_2972.jpg" style={{width:"100%"}}></img>
   
   <img src="images/poddasze/369595143_135408969623426_2140716931892058921_n.jpg" style={{width:"100%"}}></img>

</div> 
</div>
</div>
    </div>
        );
    }
    
    export default Poddasze;