import React, {useState} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebook , faInstagram ,faPhone} from '@fortawesome/free-brands-svg-icons';
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import {Link} from 'react-router-dom';
import './Navbar.css';


import './Home.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  
    return (
        <>
           <div className="App">
        
            <div className="title-header">
                <div className="header-left" >
                <Link to='/' className="tytul" onClick={closeMobileMenu}> <img src="szkic_logo_poziom_napisrowno_beztla.svg" className='logo'></img></Link>
                </div>
            <IconContext.Provider value={{ color: "black" }}>
            <nav className="navbar">

            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="navbar-item"><Link  className={({ isActive }) =>
                    "link-styles" + (isActive ? " activated" : "")} onClick={closeMobileMenu} to='/o-nas'>O nas</Link></li>
              <li className="navbar-item"><Link className={({ isActive }) =>
                    "link-styles" + (isActive ? " activated" : "")} onClick={closeMobileMenu} to='/galeria'>Galeria</Link></li>
              <li className="navbar-item"><Link className={({ isActive }) =>
                    "link-styles" + (isActive ? " activated" : "")} onClick={closeMobileMenu} to='/cennik'>Cennik</Link></li>
              <li className="navbar-item"><Link className={({ isActive }) =>
                    "link-styles" + (isActive ? " activated" : "")} onClick={closeMobileMenu} to='/rezerwacje'>Rezerwacje</Link></li> 
              <li className="navbar-item"><Link className={({ isActive }) =>
                    "link-styles" + (isActive ? " activated" : "")} onClick={closeMobileMenu} to='/kontakt'>Kontakt</Link></li>
            
        
              <li className="navbar-item-icons"><a href="https://www.facebook.com/profile.php?id=100094629690454" > <h4><FontAwesomeIcon icon={faFacebook} size = '1x'  /></h4></a></li>
              <li className="navbar-item-icons"><a href="https://www.instagram.com/siedliskowsloncu/"><h4><FontAwesomeIcon icon={faInstagram} size = '1x' /></h4></a></li>
              <li className="navbar-item-icons"><a href="tel:+4733378901"><h4><i class="fa fa-phone"></i></h4></a></li>
              <li className="navbar-item-icons"><a href="mailto:webmaster@example.com"><h4><i class="fa fa-envelope"></i></h4></a></li>
            </ul>
            </nav>  
            </IconContext.Provider>
            
            
            </div>
</div>
</>
  );
}

export default Navbar;