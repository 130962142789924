import React from 'react';
import './zdjecia.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];
const Pokoje = () => {
    return (
        <div className='div-zdjecia'>
    {/* //        <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={[{
    //                     breakpoint: 800,
    //                     settings: {
    //                         slidesToShow: 3,
    //                         slidesToScroll: 3
    //                     }
    //                     }, {
    //                     breakpoint: 500,
    //                     settings: {
    //                         slidesToShow: 2,
    //                         slidesToScroll: 2
    //                     }
    //                     }]}>
    //     <div style={{
    //             textAlign: 'center',
    //             background: 'pink',
    //             height: '100%',
    //             justifyContent: 'center'
    //         }}><img src="poddasze.jpg" width={'100%'} style={{verticalAlign:"middle"}}></img></div>
    //     <div style={{
    //              textAlign: 'center',
    //              background: 'red',
    //              width: '100%',
    //          }}><img src="pokoje.jpg"  ></img></div>
    //     <div style={{
    //             textAlign: 'center',
    //             background: 'yellow',
    //             padding: '200px 0',
    //             fontSize: '30px'
    //         }}>Third Slide</div>
    //     <div style={{
    //             textAlign: 'center',
    //             background: 'green',
    //             padding: '200px 0',
    //             fontSize: '30px'
    //         }}>Fourth Slide</div>
    //     <div style={{
    //             textAlign: 'center',
    //             background: 'blue',
    //             padding: '200px 0',
    //             fontSize: '30px'
    //         }}>Sixth Slide</div>
    //     <div style={{
    //             textAlign: 'center',
    //             background: 'indigo',
    //             padding: '200px 0',
    //             fontSize: '30px'
    //         }}>Seventh Slide</div>
    //     <div style={{
    //             textAlign: 'center',
    //             background: 'violet',
    //             padding: '200px 0',
    //             fontSize: '30px'
    //         }}>Eight Slide</div>
    // </Slide> */}


    <div className='grid'>

    <div class="row"> 
  <div class="column">
 
    <img src="images/pokoje/369604822_135409306290059_5518783340183370608_n.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_3171.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_6441.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_6443.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_2957.jpg" style={{width:"100%"}}></img>
   
  </div>
  <div class="column">
  <img src="images/pokoje/IMG_6743_Easy-Resize.com.jpg" style={{width:"100%"}}></img>
  <img src="images/pokoje/IMG_3007.jpg" style={{width:"100%"}}></img>
  <img src="images/pokoje/IMG_3027.jpg" style={{width:"100%"}}></img>
  <img src="images/pokoje/IMG_2997.jpg" style={{width:"100%"}}></img>
  <img src="images/pokoje/IMG_3061.jpg" style={{width:"100%"}}></img>
  <img src="images/pokoje/IMG_6436.jpg" style={{width:"100%"}}></img>
  </div>  
  <div class="column"> 
  
    <img src="images/pokoje/IMG_6414.jpg" style={{width:"100%"}}></img> 
    <img src="images/pokoje/IMG_6418jpg.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_3038.jpg" style={{width:"100%"}}></img>
    
    <img src="images/pokoje/IMG_6421.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_6437.jpg" style={{width:"100%"}}></img>
   
  </div>
  <div class="column">
  <img src="images/pokoje/IMG_3048.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_3042.jpg" style={{width:"100%"}}></img> 
    <img src="images/pokoje/369645181_135408379623485_8655222196162442959_n.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_6405_Easy-Resize.com.jpg" style={{width:"100%"}}></img>
    <img src="images/pokoje/IMG_6439.jpg" style={{width:"100%"}}></img>
  </div>
</div>
    </div>
        </div>
    );
};
export default Pokoje;