import React, { Component } from 'react';
import Footer from './Footer'; 
import Home from './Home';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './components/About';
import Galery from './components/Galery';
import Price from './components/Price';
import Reservations from './components/Reservations';
import Contact from './components/Contact';
import Imprezy from './components/galery/Imprezy';
import Kuchnia from './components/galery/Kuchnia';
import NaszeSiedlisko from './components/galery/NaszeSiedlisko';
import OkoliczneAtrakcje from './components/galery/OkoliczneAtrakcje';
import Poddasze from './components/galery/Poddasze';
import Pokoje from './components/galery/Pokoje';
import './App.css';

function App() {
  return (
    <>
    <Router>
    <Navbar sticky="top"/>
      <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/o-nas' element={<About/>}/>
      <Route path='/galeria' element={<Galery/>}/>
      <Route path='/cennik' element={<Price/>}/>
      <Route path='/rezerwacje' element={<Reservations/>}/>
      <Route path='/kontakt' element={<Contact/>}/>
      <Route path='/pokoje' element={<Pokoje/>}/>
      <Route path='/kuchnia' element={<Kuchnia/>}/>
      <Route path='/poddasze' element={<Poddasze/>}/>
      <Route path='/nasze-siedlisko' element={<NaszeSiedlisko/>}/>
      <Route path='/imprezy' element={<Imprezy/>}/>
      <Route path='/okoliczne-atrakcje' element={<OkoliczneAtrakcje/>}/>
      
        </Routes>
    <Footer />
</Router>
    </>
  );
}

export default App;