import React from 'react';
import './About.css';
import Fade from 'react-reveal/Fade';

const About = () => {
    return (

        <div className="about-div">
        <h2 className='about-div-h2'>Siedlisko w Słońcu Podlasia</h2>
        <div className="about-img-text">
            <Fade left><div className="about-img">
            <img className="about-img2" src="/IMG_about2.jpg"></img></div></Fade>
            <div className="about-text" > 
            {/* <h4><i class="fa fa-leaf"></i></h4> */}
            <h4>
        Zapraszamy do wyjątkowego domu na wynajem, ukrytego wsród malowniczych krajobrazów Podlasia.
        To idealne miejsce dla grupy przyjaciół, znajomych, rodziny, pragnących odpoczynku w otoczeniu natury. 
        Z dala od sąsiadów, oferujemy niepowtarzalną atmosferę relaksu i możliwość zorganizowania niezapomnianej imprezy okolicznościowej (wieczory kawalerskie, panieńskie, urodziny, sylwester itp.).</h4> 
        <h4><i class="fa fa-leaf"></i></h4></div>     
       
       <div className="about-text">
       {/* <h4><i class="fa fa-leaf"></i></h4> */}
        <h4>
        Tu zanurzysz się w spokoju i pięknie przyrody. 
        Dodatkowo, balia na zewnątrz, stawy rekreacyjne oraz mnóstwo przestrzeni stworzą doskonałe warunki do aktywnego spędzania czasu z bliskimi na paro hektarowej działce. 
        Ciesz się wyjątkową harmonią pomiędzy komfortem, spokojem ,a możliwością zorganizowania niezapomnianych chwil dla siebie i swoich gości. 
        Czekamy na Ciebie! </h4>
        <h4><i class="fa fa-leaf" ></i></h4></div>
            <Fade right><div className="about-img"><img className="about-img2" src="/IMG_about1.jpg"></img></div></Fade>
       
        </div>

        <div className="about-spec">

        <table className="about-table">
                    <tr>
                        <th className="about-th" colspan="2">Informacje</th>
                    </tr> 
                    <tr>
                        <td className="about-td" width="30%" id="td-bold"><i class="fa fa-person"></i> Liczba miejsc</td>
                        <td className="about-td">12-16 osób</td>
                    </tr>
                    <tr>
                        <td className="about-td"id="td-bold"><i class="fa fa-bed"></i> Łóżka</td>
                        <td className="about-td">x12 pojedyńczych, x2 2-osobowe rozkładane sofy </td>
                    </tr>
                    <tr>
                        <td className="about-td"id="td-bold"><i class="fa fa-utensils"></i> Wyposażenie kuchni</td>
                        <td className="about-td">Czajnik, Ekspres, Płytka grzewcza, Talerze, Miski, Garnki, Patelnie, Durszlak, Kieliszki do wódki, wina, szampana, Kufle do piwa, Filiżanki itp.</td>
                    </tr>
                    <tr>
                    <td className="about-td"id="td-bold"><i class="fa fa-bath"></i> Atrakcje</td>
                    <td className="about-td">Balia, Ognisko, Grill, 2 stawy rekreacyjne</td>
                    </tr>
                    <td className="about-td"id="td-bold"><i class="fa fa-circle-plus"></i> Dodatkowo</td>
                    <td className="about-td">Ręczniki</td>
                    <tr>
                       <td className="about-td"id="td-bold"><i class="fa fa-location-dot"></i> W okolicy</td> 
                       <td className="about-td">7 km do Moniek (duże sklepy, stacje paliw, restauracje)</td> 
                    </tr>
                    <tr>
                       <td className="about-td"></td> 
                       <td className="about-td">19 km do plaży Goniądz, spływy kajakowe rzeką Biebrza</td> 
                    </tr>
                    <tr>
                       <td className="about-td"></td> 
                       <td className="about-td">20 km do Twierdzy Osowiec</td> 
                    </tr>
                    <tr>
                       <td className="about-td"></td> 
                       <td className="about-td">30 km do Tykocina (restauracje żydowskie, synagoga)</td> 
                    </tr>
                    <tr>
                       <td className="about-td"></td> 
                       <td className="about-td">40 km do Białegostoku</td> 
                    </tr>
                    <tr>
                       <td className="about-td"></td> 
                       <td className="about-td">200 km do Warszawy</td> 
                    </tr>
                    <tr>
                        <td className="about-td"id="td-bold">
 <i class="fa fa-map-location-dot"></i> Działka</td>
                        <td className="about-td">8ha terenu, stawy</td>
                    </tr>
                   
        </table>
        </div>
       
        
        </div>
    );
}

export default About;