import React from 'react';
import { useState } from 'react';
import Calendar from 'react-calendar';
import './Reservations.css';
import Swal from 'sweetalert2'

const Reservations = () => {
    const [value, onChange] = useState(new Date());

    const isHighlightedDate = (date) => {
        const highlightedDates = [ 
        // new Date('2024-04-27'), new Date('2024-04-28'),
        // new Date('2024-05-02'), new Date('2024-05-03'), new Date('2024-05-04'),
        // new Date('2024-05-10'), new Date('2024-05-11'),new Date('2024-05-12'),
        // new Date('2024-05-18'),new Date('2024-05-19'),
        // new Date('2024-05-24'),new Date('2024-05-25'), new Date('2024-05-26'),
        // new Date('2024-05-30'),new Date('2024-05-31'),new Date('2024-06-01'), new Date('2024-06-02'),
        // new Date('2024-06-07'),new Date('2024-06-08'),new Date('2024-06-09'),
        // new Date('2024-06-15'),new Date('2024-06-16'), 
        // new Date('2024-06-17'),new Date('2024-06-18'), new Date('2024-06-19'),new Date('2024-06-20'),
        // new Date('2024-06-21'),new Date('2024-06-22'),new Date('2024-06-23'),
        // new Date('2024-06-28'), new Date('2024-06-29'),new Date('2024-06-30'),
        // new Date('2024-07-01'),new Date('2024-07-02'), new Date('2024-07-03'),new Date('2024-07-04'),
        // new Date('2024-07-08'),new Date('2024-07-09'), new Date('2024-07-10'),new Date('2024-07-11'),
        // new Date('2024-07-05'),new Date('2024-07-06'), new Date('2024-07-07'),
        // new Date('2024-07-12'),new Date('2024-07-13'), new Date('2024-07-14'),
        // new Date('2024-07-20'),new Date('2024-07-21'),
        // new Date('2024-07-26'),new Date('2024-07-27'), new Date('2024-07-28'),
        // new Date('2024-08-03'), new Date('2024-08-04'),
        // new Date('2024-08-10'), new Date('2024-08-11'),
        // new Date('2024-08-14'), new Date('2024-08-15'),new Date('2024-08-16'),
        // new Date('2024-08-17'), new Date('2024-08-18'),
        // new Date('2024-08-19'), new Date('2024-08-20'),new Date('2024-08-21'),new Date('2024-08-22'),
        // new Date('2024-08-23'), new Date('2024-08-24'),new Date('2024-08-25'),
        // new Date('2024-08-31'),new Date('2024-09-01'),
        // new Date('2024-09-06'), new Date('2024-09-07'),new Date('2024-09-08'),
        // new Date('2024-09-13'),new Date('2024-09-14'),new Date('2024-09-15'),
        // new Date('2025-06-27'),new Date('2025-06-28'), new Date('2025-06-29'),
        // new Date('2024-09-21'), new Date('2024-09-22'),
        // new Date('2024-09-27'), new Date('2024-09-28'),new Date('2024-09-29'),
        new Date('2024-11-01'), new Date('2024-11-02'),new Date('2024-11-03 '),new Date('2024-11-04'),new Date('2024-11-05'),new Date('2024-11-06'),new Date('2024-11-07'),
        new Date('2024-11-08'),new Date('2024-11-09'),new Date('2024-11-10'),new Date('2024-11-11'),new Date('2024-11-12'),new Date('2024-11-13'),new Date('2024-11-14'),
        new Date('2024-11-15'),new Date('2024-11-16'),new Date('2024-11-17'),new Date('2024-11-18'),new Date('2024-11-19'),new Date('2024-11-20'),new Date('2024-11-21'),
        new Date('2024-11-22'),new Date('2024-11-23'),new Date('2024-11-24'),new Date('2024-11-25'),new Date('2024-11-26'),new Date('2024-11-27'),new Date('2024-11-28'),
        new Date('2024-11-29'),new Date('2024-11-30'),new Date('2024-12-01'),new Date('2024-12-02'),new Date('2024-12-03'),new Date('2024-12-04'),new Date('2024-12-05'),
        new Date('2024-12-06'),new Date('2024-12-07'),new Date('2024-12-08'),new Date('2024-12-09'),new Date('2024-12-10'),new Date('2024-12-11'),new Date('2024-12-12'),
        new Date('2024-12-31'),new Date('2025-01-01'),];
        return highlightedDates.some((highlightedDate) => date.toDateString() === highlightedDate.toDateString());
      };

      const titleClassName = ({ date }) => {
        let classes = '';
        if (date.getDay() === 0) {
           // Niedziele - tło czerwone, kolor czcionki goldenrod
      classes += ' highlighted-sunday';
        }
    
        if (isHighlightedDate(date)) {
          // Wybrane daty - tło czerwone
      classes += ' highlighted-date';
        }
    
        return classes.trim();
      };

      const [startDate, setStartDate] = useState('');
      const [endDate, setEndDate] = useState('');
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');
      const [name, setName] = useState('');

      const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Obsługa zmiany dla różnych pól
        if (name === 'startDate') {
          setStartDate(value);
        } else if (name === 'endDate') {
          setEndDate(value);
        } else if (name === 'email') {
          setEmail(value);
        } else if (name === 'message') {
          setMessage(value);
        } else if (name === 'name') {
          setName(value);
        }
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!startDate || !endDate || !email || !message || !name) {
          Swal.fire({
            icon: 'error',
            title: 'Błąd!',
            text: 'Proszę wypełnić wszystkie pola formularza.',
          });
          return;
        }
    
        const templateId = 'template_d3rmgvl';
    
        // Przekazanie wszystkich danych do funkcji sendFeedback
        sendFeedback(templateId, {
          startDate: startDate,
          endDate: endDate,
          email: email,
          message: message,
          name: name,
        });
        console.log('startDate:', startDate);
        console.log('endDate:', endDate);
      };

      const sendFeedback = (templateId, variables) => {
        window.emailjs
          .send('siedlisko_service', templateId, variables)
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: 'Poszło!',
              text: 'Twoja wiadomośc, została wysłana!',
            }).then((res) => {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            });
          })
          .catch((err) => {
            console.error('Email Error:', err);
          });
      };
      return (
        <div className="reservations-div">
          <div className="reservations-div-text">
            <h2>Kalendarz rezerwacji</h2>
            <h4>W tym miejscu, możesz sprawdzić dostępność naszego obiektu w interesującym Cię terminie.</h4>
          </div>
    
          <div className="Sample__container">
            <main className="Sample__container__content">
              <Calendar onChange={onChange} showWeekNumbers value={value} tileClassName={titleClassName} />
            </main>
          </div>
    
          <div className="legenda">
            <h4>
              <i className="fa-solid fa-square" style={{ color: 'rgb(224, 118, 118)' }}></i> Daty zajęte{' '}
              <i className="fa-solid fa-square" style={{ color: '#575757' }}></i> Zaznaczona data{' '}
              <i className="fa-solid fa-square" style={{ color: 'goldenrod' }}></i> Dzisiejszy dzień
            </h4>
          </div>
    
          <div className="reservations-form">
            <form onSubmit={handleSubmit} className="res-form">
              <h4>Możesz skorzystać z formularza poniżej, aby sprawdzić dostępność danego terminu.</h4>
              <div className="res-form-date">
                <div className="date-input">
                  <label htmlFor="startDate" id="label-res">
                    Data początkowa:
                  </label>
                  <input type="date" id="startDate" name="startDate" value={startDate} onChange={handleChange} />
                </div>
                <div className="date-input">
                  <label htmlFor="endDate" style={{ textAlign: 'left' }} id="label-res">
                    Data końcowa:
                  </label>
                  <input type="date" id="endDate" name="endDate" value={endDate} onChange={handleChange} />
                </div>
              </div>
    
              <div className="res-form-name">
                <div className="name-input">
                  <label htmlFor="name" id="label-res">
                    Imię:
                  </label>
                  <input type="text" id="name-label-res" name="name" value={name} onChange={handleChange} />
                </div>
    
                <div className="name-input">
                  <label htmlFor="email" id="label-res">
                    E-mail:
                  </label>
                  <input type="email" id="email-label-res" name="email" value={email} onChange={handleChange} />
                </div>
              </div>
    
              <div className="res-form-msg">
                <label htmlFor="message" id="label-res">
                  Wiadomość:
                </label>
                <textarea id="message-label-res" name="message" value={message} onChange={handleChange} rows="4"></textarea>
              </div>
    
              <button type="submit" className="res-button">
                Sprawdź
              </button>
            </form>
          </div>
        </div>
      );
    };
    
    export default Reservations;