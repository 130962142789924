import React from 'react';
import './Home.css';
import {Link} from 'react-router-dom';

import Fade from 'react-reveal/Fade';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


const Home = () => {
    return (
  <div className="home">
  
        <div className='home-slideshow'>  
        <Slide nextArrow={<button style={{
             
             background: 'none',
              border: '0px'
            }}><h1><i style={{color:"white"}} class="fa fa-chevron-right"></i></h1></button>}
            prevArrow={<button style={{
             
              background: 'none',
              border: '0px'
            }}><h1><i style={{color:"white"}} class="fa fa-chevron-left"></i></h1></button>}

        
         slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={true} responsive={[{
            breakpoint: 800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    }
        ]}
          
          >   
                
                  <div className="slideshow-picture" style={{
                backgroundImage: 'url("./11-min.JPG")'
            }}></div>
                  <div className="slideshow-picture" style={{
                backgroundImage: 'url("images/siedlisko/44-min.jpg")'
            }}></div>
            <div className="slideshow-picture" style={{
                backgroundImage: 'url("./22-min.JPG")'
            }}></div>
                  <div className="slideshow-picture" style={{
                backgroundImage: 'url("./IMG_5030.jpg")'
            }}></div>
                  <div className="slideshow-picture" style={{
                backgroundImage: 'url("images/imprezy/33-min.jpg")'
            }}></div>
                  <div className="slideshow-picture" style={{
                backgroundImage: 'url("./IMG_5367.jpg")'
            }}></div>
                  <div className="slideshow-picture" style={{
                backgroundImage: 'url("./IMG_5360.jpg")'
            }}></div>
                   
                   <div className="slideshow-picture" style={{
                backgroundImage: 'url("./IMG_5364.jpg")'
            }}></div>
          
            
    </Slide>

    <div className='welcome-text'><img src="szkic_logo_poziom_napisrowno_beztla.svg" className='img-welcome'></img></div>
    <div className='arrow bounce'>
     <a class="fa-solid fa-chevron-down fa-2x" href="#h-home" style={{color:"white"}} ></a>
      </div>

        </div>
    
      
      
      <div className="home-div" id="h-home">
        <div className='home-text-img'>
            <Fade bottom>
            <div className='home-text'>
                <h2 >Imprezy, Spotkania albo Wypoczynek  <i class="fa fa-champagne-glasses"></i></h2>
                <br></br>
            <p>
              Siedlisko to idealne miejsce dla grup przyjaciół, rodziny, którzy szukają idealnego miejsca w sielskim klimacie, z dala od ludzi, wśród natury.
            </p>
            <p>
            Oferujemy wynajem na imprezy okolicznościowe (wieczory kawalerskie, panieńskie, 18-stki itp).
            </p>
            <Link to='/o-nas'><button className='home-poznaj'>Poznaj nas</button></Link>
            </div>

      <div className='home-text'>
                <h2>Jeżeli masz pytania, napisz! <i class="fa fa-circle-info"></i></h2>
                <br></br>
            <p>
              W razie jakichkolwiek zapytań, skorzystaj z formularza kontaktowego na stronie lub napisz na e-mail: kontakt@siedliskowsloncu.pl
            </p><Link to='/kontakt'><button className='home-poznaj'>Kontakt</button></Link>   
          </div>
          


            <div className='home-text'>
                <h2>Nasza aktualna oferta <i class="fa fa-money-check-dollar"></i></h2>
                <h4></h4>
                <br></br>
            <p>
              Zapoznaj się z aktualną ofertą Siedliska na rok 2025
            </p>
            <Link to='/cennik'><button className='home-poznaj'>Oferta</button></Link>
           
            <p>
             Sprawdź wolne terminy w naszym wirtualnym kalendarzu
            </p>
             
            <Link to='/rezerwacje'><button className='home-poznaj'>Rezerwacje</button></Link>
            </div>
</Fade>
        
       
      
      </div>
      </div>

      
    </div>
    )
}

export default Home;